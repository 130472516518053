import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Headroom from 'react-headroom'

import tw from '../../tailwind.js'
import { css } from 'emotion'
import Image from 'gatsby-image'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'
import OffCanvas from './offCanvas'

const styles = {
  background: css(tw`bg-accent w-full z-50 relative`),
  container: css(tw`mx-auto max-w-xl`),
  navigation: css(
    tw`bg-accent px-2 lg:px-0 py-2 flex items-center content-center justify-between flex-wrap`
  ),
  brand: css(tw`flex justify-center flex-col w-1/2 lg:w-auto`),
  menu: css(tw`hidden lg:flex`),
  navButton: css(tw`block`),
  link: css(
    tw`text-white text-sm lg:text-base no-underline p-2 lg:p-5 block uppercase`
  ),
}

const Navigation = () => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsHome {
          headerLogo {
            fixed(width: 100, imgixParams: { fm: "png", auto: "compress" }) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Headroom
        style={{ backgroundColor: '#C6D27E' }}
        className={styles.background}
      >
        <div className={styles.container}>
          <div className={styles.navigation}>
            <div
              className={styles.brand}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/')}
              role="link"
              tabIndex="0"
            >
              <Image fixed={data.datoCmsHome.headerLogo.fixed} />
            </div>
            <OffCanvas />
            <div className={styles.menu} style={{ boxSizing: 'border-box' }}>
              <span className={styles.navButton}>
                <Link to={'/#about'} className={styles.link}>
                  About
                </Link>
              </span>
              <span className={styles.navButton}>
                <Link to={'/portfolio'} className={styles.link}>
                  Portfolio
                </Link>
              </span>
              <span className={styles.navButton}>
                <Link to={'/instablog'} className={styles.link}>
                  Instablog
                </Link>
              </span>
              <span className={styles.navButton}>
                <Link to={'/contact'} className={styles.link}>
                  Contact
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Headroom>
    )}
  />
)

export default Navigation
