import React, { Component } from 'react'
import Link from 'gatsby-link'
import { css } from 'emotion'
import tw from '../../tailwind.js'
import { HamburgerSlider } from 'react-animated-burgers'

const styles = {
  menuWrapper: css(tw`h-full lg:w-auto`),
  menu: css(
    tw`list-reset flex items-center flex-no-shrink text-white my-0 mr-6
    flex-col sm:flex-col md:flex-col lg:flex-row 
    hidden sm:hidden md:hidden lg:flex
    `
  ),
  menuItem: css(tw`p-3 text-sm`),
  dropdown: css(tw`absolute flex flex-col`),
  menuHover: css(`
    position: relative;
    &:hover{
        &:after { 
          position: absolute;
          content: ' ';
          background-color: #42B5E8;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
        }
    }
    `),
  menuLink: css(tw`no-underline text-secondary`),
  mobileMenu: css(tw`block sm:block md:block lg:hidden`),
  show: css(tw`flex flex-col w-full`),
  hide: css(tw`hidden`),
  checkbox: css(tw`hidden`),
  navButton: css(tw`block`),
  link: css(
    tw`text-white text-sm lg:text-base no-underline my-3 p-2 lg:p-5 block uppercase`
  ),
}

class OffCanvas extends Component {
  constructor() {
    super()
    this.state = {
      isChecked: false,
    }
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.toggleIsChecked = this.toggleIsChecked.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  componentDidMount() {
    this.setState({ isChecked: false })
  }

  componentWillUnmount() {}

  toggleIsChecked() {
    this.setState({ isChecked: !this.state.isChecked })
  }
  handleClick() {
    this.toggleIsChecked()
  }
  handleCheckboxChange(e) {
    this.setState({ isChecked: e.target.checked })
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.mobileMenu}>
          <HamburgerSlider
            isActive={this.state.isChecked}
            toggleButton={this.handleClick}
            buttonColor="transparent"
            barColor="white"
          />

          <input
            type="checkbox"
            id="nav-controller"
            onChange={this.handleCheckboxChange}
            checked={this.state.isChecked}
            className={styles.checkbox}
          />
        </div>
        <div
          className={this.state.isChecked ? styles.show : styles.hide}
          onClick={this.handleCheckboxChange}
        >
          <span className={styles.navButton}>
            <Link to={'/#about'} className={styles.link}>
              About
            </Link>
          </span>
          <span className={styles.navButton}>
            <Link to={'/portfolio'} className={styles.link}>
              Portfolio
            </Link>
          </span>
          <span className={styles.navButton}>
            <Link to={'/instablog'} className={styles.link}>
              Instablog
            </Link>
          </span>
          <span className={styles.navButton}>
            <Link to={'/contact'} className={styles.link}>
              Contact
            </Link>
          </span>
        </div>
      </React.Fragment>
    )
  }
}

export default OffCanvas
